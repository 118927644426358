import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import { Popup } from 'vant';
import { Rate } from 'vant';
import { Toast } from 'vant';
import { Field } from 'vant';
import { Tab, Tabs } from 'vant';
import { Badge } from 'vant';
import { Divider } from 'vant';
import { Dialog } from 'vant';
import { Uploader } from 'vant';
import { RadioGroup, Radio } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';   

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)


// 大转盘抽奖
// import { LuckyWheel} from '@lucky-canvas/vue'
// Vue.components('LuckyWheel', LuckyWheel)


import VueCookies from 'vue-cookies'
import clipboard from 'clipboard';
//注册到vue原型上
Vue.prototype.clipboard = clipboard;

import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Popup);
Vue.use(Rate);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Badge);
Vue.use(Divider);
Vue.use(Dialog);
Vue.use(VueCookies);
Vue.use(Uploader);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(ElementUI);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')