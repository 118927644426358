import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/lotterydzp',
    name: 'Lotterydzp',
    component: () => import('../views/lotterydzp.vue'),
    meta: {
      ssearchindex: false,
      bottomBar: false,
      index: 1,
    }
  },
  {
    path: '/lotterylhj',
    name: 'Lotterylhj',
    component: () => import('../views/lotterylhj.vue'),
    meta: {
      ssearchindex: false,
      bottomBar: false,
      index: 4,
    }
  },
  {
    path: '/lotteryjgg',
    name: 'Lotteryjgg',
    component: () => import('../views/lotteryjgg.vue'),
    meta: {
      ssearchindex: false,
      bottomBar: false,
      index: 2,
    }
  },
  {
    path: '/lotteryndj',
    name: 'Lotteryndj',
    component: () => import('../views/lotteryndj.vue'),
    meta: {
      ssearchindex: false,
      bottomBar: false,
      index: 3,
    }
  },
  {
    path: '/lotterysdkdzp',
    name: 'Lotterysdkdzp',
    component: () => import('../views/lotterysdkdzp.vue'),
    meta: {
      ssearchindex: false,
      bottomBar: false,
      index: 5,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.BASE_URL
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router